<template>
	<div class="page-wrapper" id="article">
		<b-container fluid>
			<bo-page-title>
			</bo-page-title>
			<b-card-header>
				<b-row>
					<b-col lg="auto">
						<b-button-group>
							<b-button v-b-tooltip.hover title="Delete"><i class="ti-trash"></i></b-button>
						</b-button-group>
					</b-col>
					<b-col lg="4">
						<bo-search />
					</b-col>
					<b-col lg="3">
					</b-col>
				</b-row>
			</b-card-header>
			<bo-card use-table>
				<div class="table-responsive">
					<table class="table table-hover table-striped table-bordered">
						<thead>
							<tr>
								<th scope="col">
									<div class="custom-control custom-checkbox mr-sm-2">
										<input type="checkbox" class="custom-control-input" id="checkbox0"
											value="check">
										<label class="custom-control-label" for="checkbox0">
										</label>
									</div>
								</th>
								<th scope="col">Title</th>
								<th scope="col">Schema Type</th>
								<th scope="col">Target Location</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>
									<div class="custom-control custom-checkbox mr-sm-2">
										<input type="checkbox" class="custom-control-input" id="checkbox0"
											value="check">
										<label class="custom-control-label" for="checkbox0">
										</label>
									</div>
								</th>
								<td>
									<div class="wrap_title_post">
										<h3>Review Software Application</h3>
									</div>
								</td>
								<td>Review</td>
								<td>
									<strong>Enable on</strong>: 10472, 18124, 7176, 11402, 18430, 16123, 10964, 12442,
									9734, 18030, 18006, 7243, 15027, 15796, 15160, 15361, 10312, 13346,
								</td>

								<td>
									<router-link class="icon_action" to="add-schema"><i class="ti-marker-alt"></i>
									</router-link>
									<a href="#" class="icon_action"><i class="ti-trash"></i></a>
								</td>
							</tr>

							<tr>
								<th>
									<div class="custom-control custom-checkbox mr-sm-2">
										<input type="checkbox" class="custom-control-input" id="checkbox0"
											value="check">
										<label class="custom-control-label" for="checkbox0">
										</label>
									</div>
								</th>
								<td>
									<div class="wrap_title_post">
										<h3>
											Article</h3>
									</div>
								</td>
								<td>
									Article</td>
								<td>
									<strong>Enable on</strong>: Globally
								</td>

								<td>
									<router-link class="icon_action" to="add-schema"><i class="ti-marker-alt"></i>
									</router-link>
									<a href="#" class="icon_action"><i class="ti-trash"></i></a>
								</td>
							</tr>
							<tr>
								<th>
									<div class="custom-control custom-checkbox mr-sm-2">
										<input type="checkbox" class="custom-control-input" id="checkbox0"
											value="check">
										<label class="custom-control-label" for="checkbox0">
										</label>
									</div>
								</th>
								<td>
									<div class="wrap_title_post">
										<h3>FAQ</h3>
									</div>
								</td>
								<td>FAQ</td>
								<td>
									<strong>Enable on</strong>: 10312, 12817, 10214, 13591, 10312, 4111,
								</td>
								<td>
									<router-link class="icon_action" to="add-schema"><i class="ti-marker-alt"></i>
									</router-link>
									<a href="#" class="icon_action"><i class="ti-trash"></i></a>
								</td>
							</tr>
							<tr>
								<th>
									<div class="custom-control custom-checkbox mr-sm-2">
										<input type="checkbox" class="custom-control-input" id="checkbox0"
											value="check">
										<label class="custom-control-label" for="checkbox0">
										</label>
									</div>
								</th>
								<td>
									<div class="wrap_title_post">
										<h3>Post</h3>
									</div>
								</td>
								<td>Blogposting</td>
								<td>
									<strong>Enable on</strong>: post
								</td>
								<td>
									<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
									<a href="#" class="icon_action"><i class="ti-trash"></i></a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<template #footer>
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev"
						next-text="Next" />
				</template>
			</bo-card>
		</b-container>
	</div>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'
	export default {
		name: 'Article',
		extends: GlobalVue,
		components: {},
		mounted() {
			this.loadSeoData()
			this.loadWorkData()
			this.loadHeroData()
			if (!this.isList) {
				if (!this.isAdd) {
					this.workRow = this.works[this.$route.params.slug - 1]
				} else {
					this.workRow = {
						status: 'Y'
					}
				}
			}
		},

		computed: {
			dataSrc() {
				return this.dummyData.ourWork
			},
		},

		methods: {
			loadSeoData() {
				let datas = this.dataSrc.seo
				this.seoRow = datas
			},
			loadHeroData() {
				let datas = this.dataSrc.heroImage
				this.heroData = datas
			},
			loadWorkData() {
				let datas = this.dataSrc.works
				datas.forEach(el => {
					this.works.push(el)
				})
			},
			handleWorkSubmit(key) {
				if (key) {
					this.works.splice(key, 1, this.workRow)
					this.confirmChanges('update', 'Case Study')
				} else {
					this.works.push(this.workRow)
					this.confirmChanges('add', 'Case Study')
				}
				this.$nextTick(() => this.$router.push({
					name: 'BoOurWork'
				}))
			},
			updateHero() {
				this.heroCollapse = true
				this.heroRow = this.heroData
			},
			closeHero() {
				this.heroCollapse = false
			},
		},
		data() {
			return {
				isHidden: false,
				heroData: {},
				heroRow: {},
				seoRow: {},
				works: [],
				workRow: {},
				rows: 30,
				perPage: 1,
				currentPage: 5,
				value: '',
				value2: '',
				heroCollapse: false,
				selected_status: {
					label: 'All Status',
					value: ''
				},
				status: [
					'Active',
					'Inactive',
				],
				selected: 'A',
				ads_device: [{
						item: 'A',
						name: 'Desktop'
					},
					{
						item: 'B',
						name: 'Mobile'
					}
				],
				selected_ads_size: 'Select Ads Size',
				ads_size: [
					'Select Ads Size',
					'300x250',
					'336x280',
					'728x90',
					'300x600',
					'320x100',
					'200x50',
					'320x20',
				],
				selected_category: 'category 1',
				category: [
					'category 1',
					'category 2',
				],

				selected_options: 'Publish',
				options: [
					'Draft',
					'Publish',
				],
			}
		},
		watch: {
			$route() {
				if (!this.isList) {
					if (!this.isAdd) {
						this.workRow = this.works[this.$route.params.slug - 1]
					} else {
						this.workRow = {
							status: 'Y'
						}
					}
				}
			}
		}
	}
</script>